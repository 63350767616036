import React from 'react';
import './header.css';
import { useAtom } from 'jotai';
import { activePage } from '../../App';

function Header() {
  const [currentPage, setCurrentPage] = useAtom(activePage);

  const handleNavigation = (page) => {
    setCurrentPage(page);
  };

  return (
    <div className="header-container">
      {/* Left Section: Logo */}
      <div className="header-left">
        <div className="logo" onClick={() => handleNavigation('Home')}>
          <svg className="logo-svg" width="40" height="40" viewBox="0 0 40 40">
            <path 
              d="M20 2L2 12V28L20 38L38 28V12L20 2Z" 
              fill="none" 
              stroke="white" 
              strokeWidth="2"
            />
            <path 
              d="M20 8L8 14V26L20 32L32 26V14L20 8Z" 
              fill="none" 
              stroke="white" 
              strokeWidth="2"
            />
            <circle 
              cx="20" 
              cy="20" 
              r="4" 
              fill="white"
            />
          </svg>
          <span className="logo-text">Proving.systems</span>
        </div>
      </div>

      {/* Right Section: Navigation */}
      <div className="header-right">
        <nav className="nav-menu">
          <button 
            className={`nav-link ${currentPage === 'Home' ? 'active' : ''}`}
            onClick={() => handleNavigation('Home')}
          >
            Home
          </button>
          <button 
            className={`nav-link ${currentPage === 'Services' ? 'active' : ''}`}
            onClick={() => handleNavigation('Services')}
          >
            Services
          </button>
          <button 
            className={`nav-link ${currentPage === 'Products' ? 'active' : ''}`}
            onClick={() => handleNavigation('Products')}
          >
            Products
          </button>
          <button 
            className="login-button"
            onClick={() => handleNavigation('Login')}
          >
            Login
          </button>
        </nav>
      </div>
    </div>
  );
}

export default Header;
