import React from 'react';
import './home.css';
import backgroundVideo from '../../assets/videos/background.mp4';

function Home() {
  return (
    <div className="home-container">
      <video autoPlay muted loop className="background-video">
        <source src={backgroundVideo} type="video/mp4" />
      </video>
    </div>
  );
}

export default Home;
