import './App.css';
import Header from './assets/titlebar/header';
import { atom, useAtom } from 'jotai';

// Import pages
import Home from './pages/home/home';
import Services from './pages/services/services';
import Products from './pages/products/products';
import Login from './pages/login/login';

// Export atom so it can be used by other components
export const activePage = atom("Home");

function App() {
  const [currentPage] = useAtom(activePage);

  const renderPage = () => {
    switch(currentPage) {
      case 'Home':
        return <Home />;
      case 'Services':
        return <Services />;
      case 'Products':
        return <Products />;
      case 'Login':
        return <Login />;
      default:
        return <Home />;
    }
  };

  return (
    <div className="App">
      <Header />
      {renderPage()}
    </div>
  );
}

export default App;
