import React, { useState } from 'react';
import './products.css';
import backgroundVideo from '../../assets/videos/background.mp4';

const products = [
  {
    id: 1,
    title: "Basic Package",
    price: "$99/mo",
    features: [
      "Custom Website",
      "Cloud Hosting",
      "SSL Certificate",
      "Basic Analytics"
    ]
  },
  {
    id: 2,
    title: "Professional",
    price: "$199/mo",
    features: [
      "Everything in Basic",
      "Advanced Analytics",
      "Priority Support",
      "SEO Tools"
    ]
  },
  {
    id: 3,
    title: "Enterprise",
    price: "Custom",
    features: [
      "Everything in Professional",
      "Custom Features",
      "24/7 Support",
      "API Access"
    ]
  }
];

function Products() {
  const [activeProduct, setActiveProduct] = useState(null);

  return (
    <div className="products-container">
      <video autoPlay muted loop className="background-video">
        <source src={backgroundVideo} type="video/mp4" />
      </video>
      <div className="content-overlay">
        <div className="products-grid">
          {products.map((product) => (
            <div 
              key={product.id}
              className={`product-card ${activeProduct === product.id ? 'active' : ''}`}
              onMouseEnter={() => setActiveProduct(product.id)}
              onMouseLeave={() => setActiveProduct(null)}
            >
              <div className="product-content">
                <h2>{product.title}</h2>
                <div className="price">{product.price}</div>
                <ul>
                  {product.features.map((feature, index) => (
                    <li key={index}>{feature}</li>
                  ))}
                </ul>
                <button className="select-button">
                  Select Plan
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Products;
