import React, { useState } from 'react';
import './services.css';
import backgroundVideo from '../../assets/videos/background.mp4';

const services = [
  {
    id: 1,
    title: "Website Development",
    description: "Custom automotive websites built for performance",
    features: ["Responsive Design", "SEO Optimized", "Fast Loading", "Modern UI/UX"]
  },
  {
    id: 2,
    title: "Cloud Hosting",
    description: "Reliable and secure hosting solutions",
    features: ["99.9% Uptime", "Daily Backups", "SSL Security", "CDN Integration"]
  },
  {
    id: 3,
    title: "Analytics",
    description: "Deep insights into your website performance",
    features: ["Real-time Data", "User Behavior", "Traffic Analysis", "Conversion Tracking"]
  }
];

function Services() {
  const [activeService, setActiveService] = useState(0);
  const [isNavigating, setIsNavigating] = useState(false);

  const handleNext = () => {
    if (isNavigating) return;
    setIsNavigating(true);
    setActiveService((prev) => (prev + 1) % services.length);
    setTimeout(() => setIsNavigating(false), 500);
  };

  const handlePrev = () => {
    if (isNavigating) return;
    setIsNavigating(true);
    setActiveService((prev) => (prev - 1 + services.length) % services.length);
    setTimeout(() => setIsNavigating(false), 500);
  };

  return (
    <div className="services-container">
      <video autoPlay muted loop className="background-video">
        <source src={backgroundVideo} type="video/mp4" />
      </video>
      <div className="content-overlay">
        <div className="services-content">
          <div className="service-navigation">
            <button 
              className="nav-button prev" 
              onClick={handlePrev}
              disabled={isNavigating}
            >
              ←
            </button>
            
            <div className="service-card">
              <h2>{services[activeService].title}</h2>
              <p>{services[activeService].description}</p>
              <ul>
                {services[activeService].features.map((feature, index) => (
                  <li key={index}>{feature}</li>
                ))}
              </ul>
            </div>

            <button 
              className="nav-button next" 
              onClick={handleNext}
              disabled={isNavigating}
            >
              →
            </button>
          </div>

          <div className="service-indicators">
            {services.map((_, index) => (
              <div 
                key={index}
                className={`indicator ${index === activeService ? 'active' : ''}`}
                onClick={() => !isNavigating && setActiveService(index)}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Services;
